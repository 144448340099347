.fallback {
	text-align: center;
	padding: 10px;
}
.fallback__subtitle {
	font-size: 2rem;
	font-weight: normal;
	color: #525260;
}
.fallback__header {
	font-size: 4rem;
	font-weight: 900;
	color: #2f2f37;
	letter-spacing: 3px;
	margin-bottom: 10px;
}
