.footer {
	font-size: 14px;
	position: fixed;
	bottom: 0;
	right: 0;
	z-index: 9999;
	background-color: white;
	border-radius: 10px 0px 0px 0px;
}
.footer__content {
	display: flex;
	flex-direction: row;
	padding: 10px;
	/* opacity: 0.5; */
	z-index: 9999;
	color: black;
}
.footer__content__version {
	font-size: 7px;
	vertical-align: bottom;
	align-self: center;
}
