.chip {
	border-radius: 10px;
	margin: auto;
	padding: 5px;
	text-align: center;
	font-size: calc(10px + 0.5vw);
	letter-spacing: 2px;
	word-break: break-all;
	display: inline-block;
}
@media (max-width: 700px) {
	.chip {
		border-radius: 6px !important;
	}
}
