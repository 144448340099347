.wrapper-bo {
	position: relative;
	display: flex;
	flex-direction: column;
	background-color: #8ec5fc;
	background: linear-gradient(90deg, #8e2de2 0%, #4a00e0 100%);
	height: 100vh;
}

.avatar {
	position: absolute;
	top: -99px;
	right: calc(50% - 110px);
	z-index: 9998;
}

.chip-container {
	margin-top: 110px;
}

@media (max-width: 700px) {
	.wrapper {
		background-color: white;
		border-radius: 20px 20px 0px 0px !important;
	}

	.wrapper-bo {
		/* background: linear-gradient(153.06deg, #6D17CB -0.31%, #2876F9 99.69%); */
	}

	.avatar {
		position: absolute;
		top: -60px !important;
		right: calc(50% - 107px) !important;
		z-index: 0;
	}

	.chip-container {
		margin-top: 180px;
	}
}

.wrapper {
	position: relative;
	background-color: white;
	border-radius: 30px 30px 0px 0px;
	margin: auto;
	width: 100%;
	max-width: 700px;
	height: 100%;
}

.wrapper-top {
	height: 300px;
	width: 100%;
	/* background-color: green; */
}

.header {
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 1000;
}

.lottie {
	position: absolute;
	top: -30px;
	right: 0;
}

.name {
	color: #444361;
}
