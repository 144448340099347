.Header {
	position: fixed;
	top: 0; /* Stick it to the top */
	max-height: 70px;
	width: 100vw;
	display: grid;
	grid-template-areas: "logo nav";
	/* Cosmetics */
	background: linear-gradient(90deg, #8e2de2 0%, #4a00e0 100%);
	/* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
	z-index: 2;
}

.Logo {
	grid-area: logo;
	height: auto;
	padding-bottom: 10px;
	padding-top: 10px;
	padding-left: 20px;
}

.Nav {
	/* display: grid;
    grid-area: nav;
    grid-template-columns: repeat(4, auto); */
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 10px;
	justify-content: flex-end;
	align-items: center;
}
.Nav a {
	color: #fff;
	font-size: 16px;
	font-weight: 500;
	transition: 0.5s;
	text-decoration: none;
	padding-right: 50px;
}
.Nav a:hover {
	transform: scale(1.1);
	padding-left: 7px;
	text-decoration: underline;
	text-decoration-thickness: 2px;
	text-underline-offset: 5px;
}
.Nav button {
	padding: 10px;
	outline: none;
	border: none;
	font-size: 20px;
	color: #fff;
	font-weight: 600;
	background-color: rgba(255, 0, 0, 0.5);
	box-shadow: 0px 5px 0px 0px rgba(255, 0, 0, 0.25);
	border-radius: 10px;
	cursor: pointer;
	transition: 70ms;
}

.Nav button:active {
	transform: translateY(3px);
	box-shadow: 0px 2px 0px 0px rgba(255, 0, 0, 0.25);
}

.Burger {
	display: none;
	grid-area: burger;
	margin: 0 20px 0 0;
	padding: 0;
	justify-self: flex-end;
	font-size: 40px;
	border: none;
	background: none;
	outline: none;
	transition: 0.1s;
}
.Burger:active {
	transform: scale(1.2);
}

@media (max-width: 700px) {
	.Header {
		grid-template-areas: "logo burger" "nav nav";
	}
	.Nav {
		display: grid;
		grid-area: nav;
		grid-template-rows: repeat(3, auto);
		grid-template-columns: none;
		grid-row-gap: 20px;
		justify-content: start;
		align-items: center;
		padding: 30px 0 30px;
		padding-left: 20px;

		backdrop-filter: blur(73px);
		/* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		z-index: 9999;
	}
	.Burger {
		display: inline;
	}
}

.NavAnimation-enter {
	opacity: 0;
	transform: scale(0.5);
}
.NavAnimation-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition: opacity 350ms, transform 350ms;
}
.NavAnimation-exit {
	opacity: 1;
}
.NavAnimation-exit-active {
	opacity: 0;
	transform: scale(0.5);
	transition: opacity 350ms, transform 350ms;
}
