html {
	height: 100%;
	/* background: linear-gradient(90deg, #8e2de2 0%, #4a00e0 100%); */
}

body {
	margin: 0;
	font-family: "Poppins";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
