.main-map-wrapper {
	height: 85vh;
	z-index: 1;
	font-family: "Poppins";
}
main {
	padding: 70px 0px 0px 0;
	width: 100%;
}
.sshhs {
	width: 35px;
	height: 35px;
}
.badge-wrapper {
	color: var(sss);
	background-color: rgb(6, 0, 75);
	width: 15px;
	height: 15px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: -8px;
}

.badge {
	font-size: 19px;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 10px;
	color: #4a00e0;
	font-weight: 600;
}
.marker-img {
	border-radius: 50%;
	width: 35px;
	height: 35px;
	background-size: contain;
	border: 3px solid white;
}
.textDecor {
	text-decoration: underline;
	text-decoration-thickness: 1px;
	text-underline-offset: 5px;
}
.avatar-stack {
	display: flex;
	flex-direction: row;
}
.avatar-stack > img {
	margin-left: -20px;
}
.marker-img-no {
	border-radius: 50%;
	width: 35px;
	height: 35px;
	border: 3px solid white;
	background-color: white;
	text-align: center;
	margin-left: -25px;
}
.leaflet-control-container > .leaflet-right {
	right: auto;
}
